<template>
  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug}}">
    <img :src="tshirt" alt="Camiseta" class="tshirt">
    <p class="mb-0">{{ player.nick }}</p>
  </router-link>
</template>
<script>
export default {
  props: {
    player: {
      required: true,
      type: Object
    },
    lang: {
      required: true,
      type: String
    },
    console: {
      required: true,
      type: String
    },
    tshirt: {
      required: true,
      type: String
    }
  }
}
</script>
